<script setup>
import CommunityListTableVue from '../../../components/Community/CommunityListTable.vue'
import PaginationBarVue from '../../../components/ui/PaginationBar.vue'
// import SearchBarVue from '../../../components/ui/SearchBar.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import { getNotice } from '@/api/platform/community' // 공지사항 조회

import LanguageConverter from '@/util/LanguageConverter'

import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const isLoading = ref(false)
const dataCount = ref(0)

const paginationClickHandler = async (e) => {
  noticeList.value = await LoadData(e.itemIdx)
}

const noticeList = ref([])

const LoadData = async (pageIdx = 1) => {
  isLoading.value = true
  const offset = (pageIdx - 1) * 10
  const data = await getNotice({ limit: 10, offset, noticeType: 0 })

  if (data.code) {
    isLoading.value = false
    return null
  }
  if (data.status) {
    isLoading.value = false
    return null
  }

  dataCount.value = data.count

  const result = data.items.map((item, idx) => {
    return {
      no: offset + idx + 1,
      Id: item.noticeId,
      title: item.title,
      createdAt: item.createdAt.substr(0, 10)
    }
  })

  isLoading.value = false
  return result
}

onMounted(async () => {
  noticeList.value = await LoadData()
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="body-section">
    <h2 class="wf__community-default-title">{{ LanguageConverter({ en: 'PromisingLabs Notice', kr: '유망연구실 공지사항' }) }}</h2>

    <CommunityListTableVue v-if="noticeList" class="mb-5" :postList="noticeList" :baseUrl="route.path" />
    <div v-else>결과가 없습니다.</div>

    <PaginationBarVue v-if="dataCount !== 0" :totalItems="dataCount" :itemsPerPage="10" @onItemClick="paginationClickHandler" />
  </section>

  <section class="search-section">
    <!-- <SearchBarVue /> -->
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.head-section {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}
.wf__subtitle {
  font-size: 1.25rem;
}
.body-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 5em;
  margin-bottom: 3em;
}
.body-section h2 {
  font-weight: bold;
  margin-bottom: 1em;
}
.search-section {
  margin-bottom: 5em;
}
</style>
